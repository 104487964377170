<template>
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	viewBox="0 0 64 64"  enable-background="new 0 0 64 64" width="12px" height="12px" xml:space="preserve">
    <title>将任务调度到今天</title>
	<g>
	<g id="XMLID_9_">
		<g>
			<g>
				<path fill="#F4824A" d="M31,22c0.2,0,0.4,0,0.5,0c2.4,0,4.4-0.8,5.2-2l1.3-2.6c0.9,0.5,1.8,1,2.9,1.2c2.9,0.8,5.9,0.1,8-0.7
					l0.1,0.4c4.8,4.7,7.8,11.3,7.8,18.6c0,14.3-11.6,25.9-25.9,25.9C16.6,62.8,5,51.2,5,36.9C5,22.6,16.6,11,30.9,11
					c0.4,0,0.7,0,1.1,0h0c0,0,0,0.1,0,0.1c-1.3,5.2-1,9.9-1,9.9L31,22z"/>
			</g>
			<g>
				<path fill="#6DE283" d="M43.4,8.9c5.6,1.5,8.9,7.5,8.9,7.5s-1.3,0.9-3.4,1.6c-2.2,0.8-5.1,1.4-8,0.7c-1-0.3-2-0.7-2.9-1.2
					c-3.8-2.3-6-6.3-6-6.3S37.8,7.5,43.4,8.9z"/>
			</g>
			<g>
				<path fill="#F4824A" d="M38,17.4L36.7,20c-0.8,1.2-2.8,2-5.2,2c-0.2,0-0.4,0-0.5,0l0-0.9c0,0-0.3-4.7,1-9.9l0,0
					C32,11.2,34.2,15.2,38,17.4z"/>
			</g>
		</g>
		<g>
			<g>
				<path fill="#2C2C3D" d="M30.9,63.8C16.1,63.8,4,51.8,4,36.9C4,22.1,16.1,10,30.9,10c0.4,0,0.7,0,1.1,0c0.6,0,1,0.5,1,1
					c0,0.6-0.5,1-1,1c-0.4,0-0.7,0-1.1,0C17.2,12,6,23.2,6,36.9c0,13.7,11.2,24.9,24.9,24.9c13.7,0,24.9-11.2,24.9-24.9
					c0-6.8-2.7-13.1-7.5-17.9c-0.4-0.4-0.4-1,0-1.4c0.4-0.4,1-0.4,1.4,0c5.2,5.1,8.1,12,8.1,19.3C57.8,51.8,45.8,63.8,30.9,63.8z"/>
			</g>
			<g>
				<path fill="#2C2C3D" d="M43.6,20c-1,0-2-0.1-3-0.4c-1.1-0.3-2.1-0.7-3.2-1.3c-4-2.4-6.2-6.5-6.3-6.6c-0.2-0.5-0.1-1,0.3-1.3
					c0.2-0.1,4.3-2.7,9.2-2.7c1,0,2,0.1,3,0.4c6,1.6,9.3,7.7,9.5,8c0.2,0.5,0.1,1-0.3,1.3c-0.1,0-1.5,0.9-3.6,1.7
					C47.3,19.6,45.4,20,43.6,20z M33.4,11.5c0.8,1.2,2.6,3.6,5.1,5c0.9,0.5,1.7,0.9,2.6,1.1c2.2,0.6,4.7,0.3,7.4-0.6
					c1-0.3,1.7-0.7,2.3-1c-1.1-1.6-3.8-5.1-7.7-6.2c-0.8-0.2-1.6-0.3-2.5-0.3C37.6,9.6,34.8,10.8,33.4,11.5z"/>
			</g>
			<g>
				<path fill="#2C2C3D" d="M31,22c-0.5,0-1-0.4-1-0.9c0-0.2-0.3-4.9,1-10.2c0,0,0-0.1,0-0.1c1.2-4.7,3.4-8.3,6.4-10.6
					c0.4-0.3,1.1-0.3,1.4,0.2c0.3,0.4,0.3,1.1-0.2,1.4C36,3.9,34.1,7,33,11.3c0,0,0,0.1,0,0.1C31.7,16.3,32,20.9,32,21
					C32,21.5,31.6,22,31,22C31,22,31,22,31,22z"/>
			</g>
			<g>
				<path fill="#2C2C3D" d="M31.5,23c-0.2,0-0.4,0-0.7,0c-2.4-0.1-4.5-1.1-5.4-2.4c-0.3-0.5-0.2-1.1,0.3-1.4
					c0.5-0.3,1.1-0.2,1.4,0.3c0.4,0.7,1.8,1.4,3.9,1.5c0.2,0,0.3,0,0.5,0c2.2,0,3.9-0.8,4.4-1.5c0.3-0.5,0.9-0.6,1.4-0.3
					c0.5,0.3,0.6,0.9,0.3,1.4C36.6,22,34.2,23,31.5,23z"/>
			</g>
		</g>
	</g>
</g>
</svg>
   
    
    </template>
    
    <script>
    export default {
       name: 'Todayword',
    }
    </script>
    
    <style scoped>
  
    </style>