<template>
    <div class="q-pa-md difficultyzone">
      <!-- 难度系数 -->
      <q-badge color="brown">
        难度系数
      </q-badge>
      <q-slider
        v-model="localDifficultyValue"
        :min="0"
        :max="10"
        :step="1"
        label
        switch-marker-labels-side
        :label-value="localDifficultyValue"
        label-always
        color="brown"
      />
  
      <!-- 情绪指数 -->
      <q-badge color="red">
        情绪指数
      </q-badge>
      <q-slider
        v-model="localMoodValue"
        :min="-5"
        :max="5"
        :step="1"
        label
        :label-value="localMoodValue"
        label-always
        color="red"
      />
    </div>
  </template>
  
  <script setup>
  import { ref, watch } from 'vue';
  import { QBadge, QSlider } from 'quasar';  // 引入 Quasar 组件
  import { defineProps, defineEmits } from 'vue';
  // 正确的 defineProps 语法
  const props = defineProps({
    difficultyValue: {
      type: Number,
      default: 0
    },
    moodValue: {
      type: Number,
      default: 0

    }
  })
  
  // 定义 emit 事件
  const emit = defineEmits(['update:difficultyValue', 'update:moodValue']);
  
  // 本地响应式状态，用于绑定到 QSlider
  const localDifficultyValue = ref(props.difficultyValue);
  const localMoodValue = ref(props.moodValue);
  
  // 监听 localDifficultyValue 和 localMoodValue 的变化，并向父组件发射事件
  watch(localDifficultyValue, (newVal) => {
    emit('update:difficultyValue', newVal); // 通知父组件 difficultyValue 的变化
  });
  
  watch(localMoodValue, (newVal) => {
    emit('update:moodValue', newVal); // 通知父组件 moodValue 的变化
  });
  </script>
  
  <style scoped>
  
  </style>
  