<template>
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	viewBox="0 0 100.4 100.4" style="enable-background:new 0 0 100.4 100.4;" xml:space="preserve" width="18px" height="18px">
<g>
	<path d="M56.6,16.5v-4.4h4.1c0.8,0,1.5-0.7,1.5-1.5V3.4c0-0.8-0.7-1.5-1.5-1.5H43.2c-0.8,0-1.5,0.7-1.5,1.5v7.2
		c0,0.8,0.7,1.5,1.5,1.5h4.1v4.4c-9.7,1.1-18.3,5.6-24.8,12.2L20.8,27l2.8-2.8c0.6-0.6,0.6-1.5,0-2.1l-5.8-5.8
		c-0.6-0.6-1.5-0.6-2.1,0L7.8,24c-0.6,0.6-0.6,1.5,0,2.1l5.8,5.8c0.3,0.3,0.7,0.4,1.1,0.4s0.8-0.1,1.1-0.4l2.9-2.9l1.9,1.9
		C14.6,38,11,47.2,11,57.2c0,22.6,18.4,41,41,41s41-18.4,41-41C92.9,36.2,77,18.8,56.6,16.5z M14.7,28.8L11,25l5.6-5.6l3.7,3.7
		l-2.2,2.2c-0.3,0.3-0.7,0.7-1.1,1.1L14.7,28.8z M48.8,9.1h-4.1V4.9h14.5v4.2h-4.1c-0.8,0-1.5,0.7-1.5,1.5v5.7c-0.5,0-1.1,0-1.6,0
		s-1.1,0-1.6,0v-5.7C50.3,9.7,49.6,9.1,48.8,9.1z M51.9,95.2c-20.9,0-38-17-38-38s17-38,38-38c0.8,0,1.6,0,2.4,0.1
		c0.4,0,0.8,0.1,1.2,0.1c19.3,1.8,34.4,18.1,34.4,37.8C89.9,78.2,72.9,95.2,51.9,95.2z"/>
	<path d="M73.5,32.6c-0.1-0.1-0.2-0.2-0.3-0.3c-5.7-4.9-13.1-7.8-21.2-7.8c-18.1,0-32.7,14.7-32.7,32.7S33.9,90,51.9,90
		s32.7-14.7,32.7-32.7C84.7,47.4,80.3,38.6,73.5,32.6z M51.9,87c-16.4,0-29.7-13.3-29.7-29.7s13.3-29.7,29.7-29.7
		c6.9,0,13.2,2.4,18.2,6.3L53.9,52.5c-0.6-0.2-1.3-0.4-1.9-0.4c-2.8,0-5.1,2.3-5.1,5.1s2.3,5.1,5.1,5.1s5-2.3,5-5.1
		c0-1-0.3-2-0.8-2.8l16.3-18.7c5.7,5.4,9.2,13,9.2,21.5C81.7,73.6,68.3,87,51.9,87z M54,57.2c0,1.2-0.9,2.1-2.1,2.1
		s-2.1-0.9-2.1-2.1c0-1.1,0.9-2.1,2.1-2.1S54,56.1,54,57.2z"/>
</g>
</svg>

</template>
<script>
export default {
   name: 'timericon',
}
</script>
<style scoped  >
	.st0{fill:none;stroke:#000000;stroke-width:2;stroke-miterlimit:10;}
	.st1{fill:none;stroke:#000000;stroke-width:2;stroke-linejoin:round;stroke-miterlimit:10;}
	.st2{fill:none;stroke:#000000;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
	.st3{fill:none;stroke:#000000;stroke-width:2;stroke-linecap:round;stroke-miterlimit:10;}
	.st4{fill:none;stroke:#000000;stroke-width:2;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:3;}
</style>