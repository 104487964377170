<template>
<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
height="12px" width="12px"	viewBox="0 0 42 42" style="enable-background:new 0 0 42 42;" xml:space="preserve">
<title>{{ info }}</title>
<line style="fill:none;stroke:red;stroke-width:2;stroke-miterlimit:10;" x1="21" y1="0" x2="21" y2="42"/>
<line style="fill:none;stroke:red;stroke-width:2;stroke-miterlimit:10;" x1="42" y1="21" x2="0" y2="21"/>
</svg>
</template>

<script>
import { defineComponent} from 'vue';
export default defineComponent({
   name: 'plusbutton',
   props:["info"],
})






</script>

<style scoped>
</style>