<template>
   <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" width="10px" height="10px">
      <title>将任务放回右侧任务树</title>
      <g id="Layer_75" data-name="Layer 75">
         <path
            d="M56,54.13H28c-24.54-1-24.53-36,0-37h7.38l-4.69,4.69a1.5,1.5,0,0,0,2.12,2.13l7.25-7.25a1.52,1.52,0,0,0,0-2.13L32.81,7.31a1.5,1.5,0,0,0-2.12,2.13l4.69,4.69H28C-.52,15.31-.51,56,28,57.13H56A1.5,1.5,0,0,0,56,54.13Z" />
      </g>
   </svg>
</template>
    
<script>
export default {
   name: 'Revokeword',
}
</script>
    
<style scoped></style>