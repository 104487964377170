<template>
  <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
 viewBox="0 0 59 59" style="enable-background:new 0 0 59 59;"  width="11px" height="11px"      xml:space="preserve">
 <title>放弃</title>
 <path style="fill:#556080;" d="M48.063,16.104l-37.63-5.289l0.479-3.41c0.197-1.398,1.489-2.373,2.888-2.176l32.566,4.577
	c1.398,0.197,2.373,1.489,2.176,2.888L48.063,16.104z"/>
<path style="fill:#FFFFFF;stroke:#7383BF;stroke-width:2;stroke-linecap:round;stroke-miterlimit:10;" d="M24.141,6.683l0.557-3.961
	c0.153-1.089,1.17-1.855,2.259-1.702l7.922,1.113c1.089,0.153,1.855,1.17,1.702,2.259l-0.557,3.961L24.141,6.683z"/>
<path style="fill:#EBBA16;" d="M12.597,20h10.627l0.343-0.343L17.91,14l-5.401,5.401c-0.141,0.141-0.141,0.37,0,0.511L12.597,20z"/>
<rect x="24.567" y="17" style="fill:#DD352E;" width="8" height="3"/>
<rect x="35.567" y="17" style="fill:#694F87;" width="5" height="3"/>
<path style="fill:#556080;" d="M42.838,59H14.297c-1.507,0-2.729-1.222-2.729-2.729V20h34v36.271C45.567,57.778,44.345,59,42.838,59
z"/>
<g>
	<circle style="fill:#7383BF;" cx="16.567" cy="28" r="1"/>
	<circle style="fill:#7383BF;" cx="16.567" cy="34" r="1"/>
	<circle style="fill:#7383BF;" cx="19.567" cy="25" r="1"/>
	<circle style="fill:#7383BF;" cx="25.567" cy="25" r="1"/>
	<circle style="fill:#7383BF;" cx="19.567" cy="31" r="1"/>
	<circle style="fill:#7383BF;" cx="25.567" cy="31" r="1"/>
	<circle style="fill:#7383BF;" cx="22.567" cy="28" r="1"/>
	<circle style="fill:#7383BF;" cx="28.567" cy="28" r="1"/>
	<circle style="fill:#7383BF;" cx="22.567" cy="34" r="1"/>
	<circle style="fill:#7383BF;" cx="28.567" cy="34" r="1"/>
	<circle style="fill:#7383BF;" cx="31.567" cy="25" r="1"/>
	<circle style="fill:#7383BF;" cx="37.567" cy="25" r="1"/>
	<circle style="fill:#7383BF;" cx="31.567" cy="31" r="1"/>
	<circle style="fill:#7383BF;" cx="37.567" cy="31" r="1"/>
	<circle style="fill:#7383BF;" cx="34.567" cy="28" r="1"/>
	<circle style="fill:#7383BF;" cx="40.567" cy="28" r="1"/>
	<circle style="fill:#7383BF;" cx="34.567" cy="34" r="1"/>
	<circle style="fill:#7383BF;" cx="40.567" cy="34" r="1"/>
	<circle style="fill:#7383BF;" cx="16.567" cy="40" r="1"/>
	<circle style="fill:#7383BF;" cx="16.567" cy="46" r="1"/>
	<circle style="fill:#7383BF;" cx="19.567" cy="37" r="1"/>
	<circle style="fill:#7383BF;" cx="25.567" cy="37" r="1"/>
	<circle style="fill:#7383BF;" cx="19.567" cy="43" r="1"/>
	<circle style="fill:#7383BF;" cx="25.567" cy="43" r="1"/>
	<circle style="fill:#7383BF;" cx="22.567" cy="40" r="1"/>
	<circle style="fill:#7383BF;" cx="28.567" cy="40" r="1"/>
	<circle style="fill:#7383BF;" cx="22.567" cy="46" r="1"/>
	<circle style="fill:#7383BF;" cx="28.567" cy="46" r="1"/>
	<circle style="fill:#7383BF;" cx="31.567" cy="37" r="1"/>
	<circle style="fill:#7383BF;" cx="37.567" cy="37" r="1"/>
	<circle style="fill:#7383BF;" cx="31.567" cy="43" r="1"/>
	<circle style="fill:#7383BF;" cx="37.567" cy="43" r="1"/>
	<circle style="fill:#7383BF;" cx="34.567" cy="40" r="1"/>
	<circle style="fill:#7383BF;" cx="40.567" cy="40" r="1"/>
	<circle style="fill:#7383BF;" cx="34.567" cy="46" r="1"/>
	<circle style="fill:#7383BF;" cx="40.567" cy="46" r="1"/>
	<circle style="fill:#7383BF;" cx="16.567" cy="52" r="1"/>
	<circle style="fill:#7383BF;" cx="19.567" cy="49" r="1"/>
	<circle style="fill:#7383BF;" cx="25.567" cy="49" r="1"/>
	<circle style="fill:#7383BF;" cx="19.567" cy="55" r="1"/>
	<circle style="fill:#7383BF;" cx="25.567" cy="55" r="1"/>
	<circle style="fill:#7383BF;" cx="22.567" cy="52" r="1"/>
	<circle style="fill:#7383BF;" cx="28.567" cy="52" r="1"/>
	<circle style="fill:#7383BF;" cx="31.567" cy="49" r="1"/>
	<circle style="fill:#7383BF;" cx="37.567" cy="49" r="1"/>
	<circle style="fill:#7383BF;" cx="31.567" cy="55" r="1"/>
	<circle style="fill:#7383BF;" cx="37.567" cy="55" r="1"/>
	<circle style="fill:#7383BF;" cx="34.567" cy="52" r="1"/>
	<circle style="fill:#7383BF;" cx="40.567" cy="52" r="1"/>
</g>
<path style="fill:#556080;" d="M48.063,16.104l-37.63-5.289l0.479-3.41c0.197-1.398,1.489-2.373,2.888-2.176l32.566,4.577
	c1.398,0.197,2.373,1.489,2.176,2.888L48.063,16.104z"/>
<path style="fill:#FFFFFF;stroke:#7383BF;stroke-width:2;stroke-linecap:round;stroke-miterlimit:10;" d="M24.141,6.683l0.557-3.961
	c0.153-1.089,1.17-1.855,2.259-1.702l7.922,1.113c1.089,0.153,1.855,1.17,1.702,2.259l-0.557,3.961L24.141,6.683z"/>
<path style="fill:#EBBA16;" d="M12.597,20h10.627l0.343-0.343L17.91,14l-5.401,5.401c-0.141,0.141-0.141,0.37,0,0.511L12.597,20z"/>
<rect x="24.567" y="17" style="fill:#DD352E;" width="8" height="3"/>
<rect x="35.567" y="17" style="fill:#694F87;" width="5" height="3"/>
<path style="fill:#556080;" d="M42.838,59H14.297c-1.507,0-2.729-1.222-2.729-2.729V20h34v36.271C45.567,57.778,44.345,59,42.838,59
	z"/>
<g>
	<circle style="fill:#7383BF;" cx="16.567" cy="28" r="1"/>
	<circle style="fill:#7383BF;" cx="16.567" cy="34" r="1"/>
	<circle style="fill:#7383BF;" cx="19.567" cy="25" r="1"/>
	<circle style="fill:#7383BF;" cx="25.567" cy="25" r="1"/>
	<circle style="fill:#7383BF;" cx="19.567" cy="31" r="1"/>
	<circle style="fill:#7383BF;" cx="25.567" cy="31" r="1"/>
	<circle style="fill:#7383BF;" cx="22.567" cy="28" r="1"/>
	<circle style="fill:#7383BF;" cx="28.567" cy="28" r="1"/>
	<circle style="fill:#7383BF;" cx="22.567" cy="34" r="1"/>
	<circle style="fill:#7383BF;" cx="28.567" cy="34" r="1"/>
	<circle style="fill:#7383BF;" cx="31.567" cy="25" r="1"/>
	<circle style="fill:#7383BF;" cx="37.567" cy="25" r="1"/>
	<circle style="fill:#7383BF;" cx="31.567" cy="31" r="1"/>
	<circle style="fill:#7383BF;" cx="37.567" cy="31" r="1"/>
	<circle style="fill:#7383BF;" cx="34.567" cy="28" r="1"/>
	<circle style="fill:#7383BF;" cx="40.567" cy="28" r="1"/>
	<circle style="fill:#7383BF;" cx="34.567" cy="34" r="1"/>
	<circle style="fill:#7383BF;" cx="40.567" cy="34" r="1"/>
	<circle style="fill:#7383BF;" cx="16.567" cy="40" r="1"/>
	<circle style="fill:#7383BF;" cx="16.567" cy="46" r="1"/>
	<circle style="fill:#7383BF;" cx="19.567" cy="37" r="1"/>
	<circle style="fill:#7383BF;" cx="25.567" cy="37" r="1"/>
	<circle style="fill:#7383BF;" cx="19.567" cy="43" r="1"/>
	<circle style="fill:#7383BF;" cx="25.567" cy="43" r="1"/>
	<circle style="fill:#7383BF;" cx="22.567" cy="40" r="1"/>
	<circle style="fill:#7383BF;" cx="28.567" cy="40" r="1"/>
	<circle style="fill:#7383BF;" cx="22.567" cy="46" r="1"/>
	<circle style="fill:#7383BF;" cx="28.567" cy="46" r="1"/>
	<circle style="fill:#7383BF;" cx="31.567" cy="37" r="1"/>
	<circle style="fill:#7383BF;" cx="37.567" cy="37" r="1"/>
	<circle style="fill:#7383BF;" cx="31.567" cy="43" r="1"/>
	<circle style="fill:#7383BF;" cx="37.567" cy="43" r="1"/>
	<circle style="fill:#7383BF;" cx="34.567" cy="40" r="1"/>
	<circle style="fill:#7383BF;" cx="40.567" cy="40" r="1"/>
	<circle style="fill:#7383BF;" cx="34.567" cy="46" r="1"/>
	<circle style="fill:#7383BF;" cx="40.567" cy="46" r="1"/>
	<circle style="fill:#7383BF;" cx="16.567" cy="52" r="1"/>
	<circle style="fill:#7383BF;" cx="19.567" cy="49" r="1"/>
	<circle style="fill:#7383BF;" cx="25.567" cy="49" r="1"/>
	<circle style="fill:#7383BF;" cx="19.567" cy="55" r="1"/>
	<circle style="fill:#7383BF;" cx="25.567" cy="55" r="1"/>
	<circle style="fill:#7383BF;" cx="22.567" cy="52" r="1"/>
	<circle style="fill:#7383BF;" cx="28.567" cy="52" r="1"/>
	<circle style="fill:#7383BF;" cx="31.567" cy="49" r="1"/>
	<circle style="fill:#7383BF;" cx="37.567" cy="49" r="1"/>
	<circle style="fill:#7383BF;" cx="31.567" cy="55" r="1"/>
	<circle style="fill:#7383BF;" cx="37.567" cy="55" r="1"/>
	<circle style="fill:#7383BF;" cx="34.567" cy="52" r="1"/>
	<circle style="fill:#7383BF;" cx="40.567" cy="52" r="1"/>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
</svg>

  </template>

<script>
export default {
   name: 'giveupword',
}
</script>

<style scoped>
#trash{
   color: yellow;
}
</style>