<template>
    <svg t="1628843650231" class="icon" title="tomorrow" viewBox="0 0 1026 1024" version="1.1"
        xmlns="http://www.w3.org/2000/svg" p-id="1986" width="12" height="12">
        <title>将任务调度到明天</title>
        <path
            d="M344.2816 283.8528a148.96 148.96 0 0 0 9.6832-52.8896c0-2.016-0.0704-4.0256-0.1472-6.0416-3.3472-83.2192-75.072-149.6448-163.1744-149.6448-90.2144 0-163.3152 69.6896-163.3152 155.6864s73.1008 155.6864 163.3152 155.6864a168.9792 168.9792 0 0 0 62.4768-11.7952l-7.7696-95.9616s75.0848 10.464 98.9312 4.96z"
            fill="#F1B353" p-id="1987"></path>
        <path
            d="M190.6432 395.328c-94.8352 0-172-73.728-172-164.3648S95.808 66.592 190.6432 66.592c92.8 0 168.2816 69.3952 171.8528 157.984 0.0768 2.1504 0.1472 4.2688 0.1472 6.3872a157.376 157.376 0 0 1-10.24 55.968 8.6912 8.6912 0 0 1-6.1696 5.3824c-19.9232 4.5952-68.9984-0.64-91.3664-3.424l6.9056 85.2608a8.6848 8.6848 0 0 1-5.4464 8.768 177.1904 177.1904 0 0 1-65.6832 12.4096z m0-311.3792c-85.2672 0-154.6368 65.952-154.6368 147.0144s69.376 147.008 154.6368 147.008a160.4096 160.4096 0 0 0 53.2992-8.9472l-7.2512-89.4272a8.6784 8.6784 0 0 1 9.856-9.2992c18.976 2.6432 69.5296 8.4032 91.2832 5.8496a140.096 140.096 0 0 0 7.456-45.184c0-1.9072-0.064-3.8208-0.1408-5.7216-3.1872-79.2128-71.0528-141.2864-154.496-141.2864z m153.6384 199.904z"
            p-id="1988"></path>
        <path
            d="M266.112 464.7424l39.4432 424.6336s95.4432 77.2224 350.24 0l32.2304-453.12s-75.968 24.6208-122.3168 23.9552-113.1968-29.44-146.9504-37.1584S328.4224 405.12 266.112 464.7424z"
            fill="#F38B51" p-id="1989"></path>
        <path
            d="M301.3504 900.2688a8.6784 8.6784 0 0 1-8.3392-6.2976 21.4592 21.4592 0 0 1-0.7296-3.84l-55.776-621.0048a21.0624 21.0624 0 0 1 1.472-9.9904 8.6784 8.6784 0 1 1 16.0896 6.5088 3.8848 3.8848 0 0 0-0.288 1.7984l55.7888 621.088a4.032 4.032 0 0 0 0.1344 0.6848 8.6848 8.6848 0 0 1-8.352 11.0528zM649.984 898.016a8.6528 8.6528 0 0 1-8.6784-8.64v-0.0704c0-0.2688 0.0128-0.5248 0.0384-0.7936l56.7936-620.9472a4.6912 4.6912 0 0 0-0.64-2.8096 8.6784 8.6784 0 1 1 15.0656-8.6208 22.0992 22.0992 0 0 1 2.8544 13.1008l-56.7808 620.768a8.704 8.704 0 0 1-8.6528 8.0128z"
            p-id="1990"></path>
        <path
            d="M217.3568 126.88c0-14.2272-11.9616-19.904-26.7136-19.904s-26.7072 5.6768-26.7072 19.904c0 5.3184 26.4704 66.3808 26.4704 66.3808-1.6832 4.3328 25.952-59.2384 26.944-66.3808z"
            fill="#FBE90B" p-id="1991"></path>
        <path
            d="M190.336 202.1504a8.6784 8.6784 0 0 1-6.3296-2.7328 8.5056 8.5056 0 0 1-2.0224-3.7568c-26.72-61.7152-26.7264-66.8416-26.7264-68.7808 0-17.632 13.5616-28.5824 35.392-28.5824s35.392 10.944 35.392 28.5824a8.896 8.896 0 0 1-0.0896 1.1968c-0.2496 1.792-0.768 5.5104-14.3808 38.016-6.9504 16.5888-10.5344 25.152-12.8704 29.728l-0.1984 0.5824-0.0832-0.0256c-2.2912 4.3584-3.3536 4.6976-5.4528 5.3696a8.608 8.608 0 0 1-2.6304 0.4032z m-17.7088-75.8272c1.1136 4.5568 8.9536 24.0128 17.8688 45.1072 7.1744-16.864 16.6784-39.8464 18.1568-45.3568-0.4288-6.912-6.4832-10.4192-18.0096-10.4192-11.6608 0-17.7152 3.584-18.016 10.6688z m44.7296 0.5568z"
        p-id="1992"></path>
    <path
        d="M104.3072 164.416c-12.928-7.104-24.064-0.0768-31.4368 12.096s-8.1984 24.896 4.7232 32c4.832 2.6624 77.3568 10.176 77.3568 10.176s-46.4896-51.9872-50.6432-54.272z"
        fill="#FBE90B" p-id="1993"></path>
    <path
        d="M154.9504 227.3792c-0.2944 0-0.5952-0.0192-0.896-0.0448-74.272-7.6992-78.8992-10.2528-80.6464-11.2064a26.816 26.816 0 0 1-13.6576-16.512 35.7312 35.7312 0 0 1 5.696-27.584c10.8928-17.9968 27.3792-23.8336 43.04-15.2192 1.4912 0.832 5.4528 3.008 52.928 56.1024a8.6784 8.6784 0 0 1-6.464 14.464zM81.2992 200.64c4.6528 1.2992 27.1616 4.1984 52.0064 6.976-15.8976-17.536-30.3488-33.0624-33.6384-35.8464-3.0528-1.6384-10.7968-4.928-19.3728 9.2352a19.7504 19.7504 0 0 0-3.776 14.112 8.928 8.928 0 0 0 4.7808 5.5232z m0.4672 0.2624z m18.2784-28.928l0.032 0.0128z"
        p-id="1994"></path>
    <path
        d="M79.8592 271.8912c-12.928 7.1104-12.1024 19.8272-4.7296 32s18.5152 19.2128 31.4368 12.096c4.832-2.656 51.7888-58.0096 51.7888-58.0096s-74.3424 11.6288-78.496 13.9136z"
        fill="#FBE90B" p-id="1995"></path>
    <path
        d="M96.3456 327.52c-10.8096 0-21.0688-6.6304-28.64-19.1232a35.7632 35.7632 0 0 1-5.696-27.5904 26.8288 26.8288 0 0 1 13.664-16.5184c1.504-0.832 5.536-3.0272 81.344-14.8864a8.6848 8.6848 0 0 1 7.9616 14.1952c-47.9872 56.5696-52.6848 59.1488-54.2208 60.0064a29.824 29.824 0 0 1-14.4128 3.9104z m-12.7744-47.7568a8.928 8.928 0 0 0-4.7936 5.5296 19.7376 19.7376 0 0 0 3.776 14.1056c8.608 14.2016 16.3712 10.8416 19.4048 9.2224 3.4624-2.9248 18.272-19.584 34.464-38.3488-25.472 4.1472-48.3712 8.1664-52.8448 9.4912z m18.8032 28.6336l-0.0704 0.0384z m-18.3488-28.8896l-0.032 0.0128z m-4.1664-7.616z"
        p-id="1996"></path>
    <path
        d="M171.5648 338.9696c0 14.2272 11.9552 19.904 26.7072 19.904s26.7136-5.6768 26.7136-19.904c0-5.312-30.2144-64.3456-30.2144-64.3456s-23.2064 59.776-23.2064 64.3456z"
        fill="#FBE90B" p-id="1997"></path>
    <path
        d="M198.272 367.552c-21.824 0-35.392-10.944-35.392-28.5824 0-1.856 0-6.1952 23.7952-67.488a8.6848 8.6848 0 0 1 7.648-5.5296 8.832 8.832 0 0 1 8.1728 4.7168c31.168 60.896 31.168 66.0864 31.168 68.3008 0 17.6384-13.568 28.5888-35.392 28.5888z m-18.0224-28.0256c0.3008 7.0848 6.3616 10.6688 18.0224 10.6688 11.5776 0 17.632-3.5328 18.0096-10.5088-1.4528-4.7744-10.368-23.52-20.4288-43.68-7.776 20.4736-14.592 39.2256-15.5968 43.52z"
        p-id="1998"></path>
    <path
        d="M305.536 205.1008c12.928-7.1168 12.1024-19.8272 4.7296-32s-18.5152-19.2128-31.4368-12.096c-4.832 2.656-44.672 56.2368-44.672 56.2368s67.2256-9.856 71.3792-12.1408z"
        fill="#FBE90B" p-id="1999"></path>
    <path
        d="M234.1568 225.92a8.6784 8.6784 0 0 1-6.9568-13.856c40.96-55.104 45.6704-57.6896 47.4432-58.6688 15.6608-8.6208 32.1472-2.7904 43.04 15.2064a35.7184 35.7184 0 0 1 5.696 27.584 26.8032 26.8032 0 0 1-13.6576 16.512c-1.5168 0.8384-5.6064 3.0592-74.2976 13.1264a8.704 8.704 0 0 1-1.2608 0.096z m49.3888-57.6064c-3.392 3.1488-16.0768 19.232-29.7152 37.1968 22.9696-3.5392 43.7248-7.0208 47.9872-8.2816a8.9216 8.9216 0 0 0 4.8-5.5296 19.7248 19.7248 0 0 0-3.776-14.1056c-8.512-14.0416-16.192-10.912-19.296-9.28z m17.8752 29.1392l-0.0704 0.0448z m-18.2912-28.9152l-0.1152 0.064z"
        p-id="2000"></path>
    <path
        d="M1012.9088 209.2672l3.7504-10.6432a13.7408 13.7408 0 0 0-9.1776-17.5744L732.6592 93.12a14.8352 14.8352 0 0 0-17.7664 7.1616L336.224 853.3632a13.6064 13.6064 0 0 0 6.8416 18.5152l5.0304 2.304a14.8864 14.8864 0 0 0 19.424-6.5216l362.7072-721.3376a14.8352 14.8352 0 0 1 17.7664-7.1616l246.4768 78.848a14.7392 14.7392 0 0 0 18.4384-8.7424z"
        fill="#EE6B29" p-id="2001"></path>
    <path
        d="M354.304 884.1984a23.5968 23.5968 0 0 1-9.8176-2.1248l-5.024-2.2976a22.6048 22.6048 0 0 1-12.096-13.12 21.8432 21.8432 0 0 1 1.1072-17.1968L707.136 96.384a23.6032 23.6032 0 0 1 28.1664-11.5264l274.8224 87.9232a22.8416 22.8416 0 0 1 13.6576 11.6288 21.824 21.824 0 0 1 1.0624 17.1008l-3.7504 10.6368a23.4944 23.4944 0 0 1-29.2672 14.1312l-246.4768-78.848a6.1696 6.1696 0 0 0-7.3664 2.7968L375.2768 871.5584a23.424 23.424 0 0 1-20.9728 12.64z m373.7408-783.1104a5.9968 5.9968 0 0 0-5.3952 3.0976L343.9808 857.2608a4.5632 4.5632 0 0 0-0.2432 3.6352 5.4144 5.4144 0 0 0 2.9376 3.0912l5.0176 2.2976a6.2464 6.2464 0 0 0 8.0768-2.528l362.7008-721.3312a23.5968 23.5968 0 0 1 28.1728-11.5264l246.4768 78.848a6.0928 6.0928 0 0 0 7.6032-3.36l3.7504-10.6432a4.5504 4.5504 0 0 0-0.2304-3.6096 5.632 5.632 0 0 0-3.4048-2.816l-274.816-87.9296a6.432 6.432 0 0 0-1.984-0.3008z m284.864 108.1792z"
        p-id="2002"></path>
    <path
        d="M262.816 473.4208a8.6784 8.6784 0 0 1-5.3696-15.5008c2.8544-2.2464 70.5728-54.9312 128.1088-51.2512 29.1584 1.8944 49.44 13.792 67.328 24.2944 17.536 10.2848 34.0928 20 57.6 20 53.3568 0 149.44-10.9504 172.8192-26.0736a8.6848 8.6848 0 0 1 9.4272 14.5728c-29.2864 18.944-134.2144 28.864-182.2528 28.864-28.2176 0-47.616-11.3856-66.3744-22.4-17.8048-10.4448-34.624-20.3136-59.6672-21.9328-50.8544-3.3536-115.616 47.0656-116.2624 47.5712a8.6464 8.6464 0 0 1-5.3568 1.856z"
        p-id="2003"></path>
    <path
        d="M329.92 747.4432a8.6784 8.6784 0 0 1-8.6272-7.8272l-25.8048-263.168a8.6784 8.6784 0 1 1 17.28-1.696L338.56 737.92a8.6848 8.6848 0 0 1-7.7888 9.4848c-0.288 0.032-0.576 0.0448-0.8576 0.0448z"
        fill="#FFFFFF" p-id="2004"></path>
    <path
        d="M475.0592 306.0736a1147.8656 1147.8656 0 0 1-163.9232-10.3552c-65.856-10.0224-74.7328-22.4768-74.7328-33.3312s8.8768-23.3152 74.7328-33.3376a1147.8336 1147.8336 0 0 1 163.9232-10.3488 1147.7824 1147.7824 0 0 1 163.9232 10.3488c65.8496 10.0224 74.7328 22.4768 74.7328 33.344s-8.8832 23.3024-74.7328 33.3248a1147.7824 1147.7824 0 0 1-163.9232 10.3552z m-220.5056-43.6864c12.064 11.1808 93.728 26.3296 220.5056 26.3296s208.4352-15.1488 220.5056-26.3296c-12.064-11.1872-93.7216-26.3296-220.5056-26.3296s-208.448 15.1424-220.5056 26.3296z m442.0736 1.2416zM474.8608 930.0928c-18.688 0-182.6176-1.1456-182.6176-40.7232s163.9232-40.7168 182.6176-40.7168 182.6176 1.1456 182.6176 40.7168-163.9232 40.7232-182.6176 40.7232z m-164.7616-40.7232c8.2304 8.8448 66.976 23.36 164.7616 23.36s156.544-14.5152 164.7616-23.36c-8.224-8.8384-66.9632-23.36-164.7616-23.36s-156.5312 14.5216-164.7616 23.36z m330.2016 0.9152z"
        p-id="2005"></path>
    <path
        d="M192.9856 395.328C98.1504 395.328 20.992 321.6 20.992 230.9632s77.1584-164.3712 171.9936-164.3712c92.8 0 168.2816 69.3952 171.8528 157.984 0.0768 2.1504 0.1472 4.2688 0.1472 6.3872a157.3888 157.3888 0 0 1-10.24 55.968l-16.2368-6.1504a140.0704 140.0704 0 0 0 9.12-49.8176c0-1.9008-0.064-3.8208-0.1408-5.7216-3.1872-79.2128-71.0592-141.2864-154.496-141.2864-85.2736 0-154.6432 65.9456-154.6432 147.008s69.376 147.008 154.6368 147.008a159.7696 159.7696 0 0 0 59.2704-11.1872l6.4064 16.1408a177.1584 177.1584 0 0 1-65.6768 12.4032z"
        p-id="2006"></path>
</svg></template>

<script>
export default {
    name: 'MySVGComponent',
}
</script>

<style scoped>.my-svg-component {
    /* Scoped CSS here */
}</style>